/* Note these styles are shared between all samples */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Titillium+Web');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* {RepositoryWarning} */
/* {RepositoryUrl}/tree/master/templates/sample/src/index.css */


#root, html, body, main {
    background: transparent;
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.igGridColumnChooserContainer {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-top: 2px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 10px;
    border: 1px gray;
    border-radius: 10px;
    box-shadow: 1px 1px 2px 2px rgba(50, 50, 50, 0.25);
}



.igContainer,
.igContainer-horizontal,
.igContainer-vertical,
.igContainer-full-size {
    background: white;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap; /* nowrap | wrap | wrap-reverse; */
    /* justify-content: flex-start; */
    /* justify-items: center; */
    /* align-items: center; */
    position: relative;
    overflow-y: hidden;
    min-width: 200px;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    height:-moz-calc(100% - 100px);
    height:-webkit-calc(100% - 100px);
    padding: 2.5px;
    margin: 0px;
}

.igContainer-full-size {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.igContainer-vertical {
    flex-flow: column;
}

.igContainer-horizontal {
    flex-flow: row;
}

/* Used by gauges */
.igContainer-center {
    background: white;
    height: calc(100% - 1rem);
    width: calc(100% - 1rem);
    padding: 0.5rem;
    margin: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* used to overlay multiple components, e.g. RadialGaugeTypeDirection */
.igContainer-relative {
    background: white;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    padding: 5px;
    margin: 0px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative
}

.igContainer-relative-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.igTitle {
    text-align: center;
    display: block;
}

.igComponent {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.igOptions,
.igOptions-horizontal,
.igOptions-vertical {
    /* background: rgb(112, 112, 111); */
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 5px;
    padding-right: 0rem;
    padding-left: 0rem;
    width: 100%;
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Titillium Web';
    display: inline-block;
    color: #585858;
}

.igOptions-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.igOptions-vertical {
    display: flex;
    flex-direction: column;
}


.igOptions > label,
.igOptions > div {
    font-family: 'Titillium Web';
}

.igOptions-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    /* font-family: Arial, Verdana, Helvetica, "Times New Roman", Times, serif; */
    display: inline-block;
    vertical-align: center;
}

.igOptions-label {
    margin-top: 0px;
    margin-left: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 5px;
    /* font-size: 11pt; */
    /* font-family: Arial, Verdana, Helvetica, "Times New Roman", Times, serif; */
    /* vertical-align: top; */
    vertical-align: center;
    padding: 0px;
    display: inline-block;
    white-space: nowrap;
    /* background: orange; */
}

.igOptions-value {
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
    /* font-size: 11pt; */
    display: inline-block;
    /* vertical-align: top; */
    width: 40px;
    padding: 0px;
    /* background-color: lime; */
}

.igOptions > slider,
.igOptions-slider {
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 20px;
    vertical-align: bottom;
    padding-top: 0px;
    min-width: 60px;
    display: inline-block;
}

.igOptions-button {
    vertical-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.igOptions > text,
.igOptions-input-text {
    margin-left: 10px;
    width: 150px;
    display: inline-block;
    vertical-align: center;
}

.igOptions > select,
.igOptions-input-select {
    font-size: 11pt;
    /* font-family: Arial, Verdana, Helvetica, "Times New Roman", Times, serif; */
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: 5px;
    vertical-align: center;
    padding: 4px;
    display: inline-block;
    /* background: green; */
}

.igLegend {
    --legend-item-overflow: hidden;
    width: calc(100% - 1rem);
    font-size: 0.75rem;
    /* font-family: Arial, Verdana, Helvetica, "Times New Roman", Times, serif; */
    margin-top: 0.25rem;
    /* margin-bottom: 0.25rem; */
    margin-left: 1rem;
    padding: 0px;
    display: inline-block;
    vertical-align: top;
}

.igLegend-title {
    /* margin-top: 0.7rem; */
    margin-left: 1rem;
    margin-right: 0.25rem;
    /* margin-bottom: 0.25rem; */
    padding-top: 0px;
    padding-bottom: 0px;
    padding: 0px;
    vertical-align: center;
    display: inline-block;
    white-space: nowrap;
    font-size: 15px;
}

.igFlex {
    display: flex;
    flex-direction: column; /* row | row-reverse | column | column-reverse*/
    flex-wrap: nowrap; /* nowrap | wrap | wrap-reverse; */
    /* justify-content: flex-start; */
    /* justify-items: center; */
    /* align-items: center; */
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    margin: 0px;
    padding: 0px;
    position: relative;
    overflow-y: hidden;
}

/* ==================================================================================== */
/* igOverlay styles used to overlay elements in front of sample container */
/* ==================================================================================== */

.igOverlay-top-left,
.igOverlay-top-right,
.igOverlay-top-center,
.igOverlay-center,
.igOverlay-bottom-left,
.igOverlay-bottom-center,
.igOverlay-bottom-right {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    font-family: 'Titillium Web';
    border-radius: 5px;
    border: 1px solid gray;
    padding: 0.5rem;
    color: black;
    /* background: rgba(228, 227, 227, 0.548); */
    background: rgba(148, 148, 148, 0.5);
    /* background: white; */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -o-user-select: none;
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.igOverlay-top-left {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
}

.igOverlay-top-right {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
}

.igOverlay-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.igOverlay-center-label {
    display: block;
    font-size: 3rem;
}

.igOverlay-top-center {
    position: absolute;
    top: 0.25rem;
    left: 50%;
    transform: translate(-50%, 0%);
}

.igOverlay-bottom-center {
    position: absolute;
    bottom: 0.25rem;
    left: 50%;
    transform: translate(-50%, 0%);
}

.igOverlay-bottom-left {
    position: absolute;
    bottom: 0.25rem;
    left: 0.25rem;
}

.igOverlay-bottom-right {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;
}


.igOverlay-row {
    /* background: red; */
    font-family: 'Titillium Web';
    /* margin-left: 0px;
    display: block;
    white-space: pre; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.igOverlay-label {
    display: block;
    font-family: 'Titillium Web';
    font-weight: 600;
}

.igOverlay-field {
    /* display: inline-block; */
    /* font-family: Lato, Helvetica, sans-serif; */
    text-align: left;
    width: 5rem;
}

.igOverlay-value {
    /* background: yellowgreen; */
    text-align: end;
    /* display: inline-block; */
    /* font-family: Lato, Helvetica, sans-serif; */
    /* width: 75px; */
    /* white-space: pre; */
}

.igOverlay-title {
    color: black;
    display: block;
    font-family: 'Titillium Web';
    font-weight: 600;
    padding-bottom: 0.5rem;
}

/* ==================================================================================== */
/* tooltip styles used in custom tooltips of charts and maps components */
/* ==================================================================================== */

.tooltipBox {
    font-size: 0.75rem;
    font-weight: normal;
    color: "Black";
    font-family: 'Titillium Web';
}

.tooltipTitle {
    display: block;
    color: "Black";
    font-weight: bold;
    font-size: 0.75rem;
    font-family: 'Titillium Web';
    margin-left: 0px;
}

.tooltipTable {
    display: table;
    margin: 0px;
    width: 100%;
    height: 100%;
    border-spacing: 5px;
    font-size: 0.75rem;
}

.tooltipHorizontal {
    display: flex;
    flex-direction: row;
}

.tooltipVertical {
    display: flex;
    flex-direction: column;
}

.tooltipRow {
    display: table-row;
}

.tooltipVal {
    display: table-cell;
    text-align: "right";
    font-weight: bold;
    font-family: 'Titillium Web';
    padding-left: 5px;
}

.tooltipLbl {
    display: table-cell;
    text-align: "left";
    font-family: 'Titillium Web';
}

.tooltipFlagImage {
    display: inline-block;
    object-fit: fill;
    height: 50px;
    width: 70px;
    margin-right: 5px;
}

.tooltipFlagBoarder {
    display: inline-block;
    padding: 0px;
    border-width: 0.5;
    border-color: rgb(177, 177, 177);
    border-style: solid;
    height: 50px;
    width: 70px;
}
