

    /* HTML marker styles */
.circle-red {
    width: 10px;
    height: 10px;
    background: coral;
    border-radius: 50%
  }

  /* .circle-blue { */
.meter{    
    width: 10px;
    height: 10px;
    background: lightblue;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
  }
  
  /* .rectangle-blue { */
 .pond{   
    width: 20px;
    height: 12px;
    background: lightskyblue;
    border-color: blue;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 20%;
  }

/* .star { */
.facility{    
    position: relative;
    
    display: inline-block;
    width: 0;
    height: 0;
    
    margin-left: .9em;
    margin-right: .9em;
    margin-bottom: 1.2em;
    
    border-right:  .3em solid transparent;
    border-bottom: .7em  solid #FC0;
    border-left:   .3em solid transparent;
  
    /* Controlls the size of the stars. */
    font-size: 12px;
    
    &:before, &:after {
      content: '';
      
      display: block;
      width: 0;
      height: 0;
      
      position: absolute;
      top: .6em;
      left: -1em;
    
      border-right:  1em solid transparent;
      border-bottom: .7em  solid #FC0;
      border-left:   1em solid transparent;
    
      transform: rotate(-35deg);
    }
    
    &:after {  
      transform: rotate(35deg);
    }
  }

.marker {
    /* Set the marker size here */
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 1.5rem;
    /* Set the marker color here */
    background: #aa3300;

    display: inline-block;
    border-bottom-right-radius: 0;
    position: relative;
    transform: rotate(45deg);

    /* optional fanciness */
    border: 1px solid #881100;
}

.wbpinactive {
  /* Set the marker size here */
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.5rem;
  /* Set the marker color here */
  background: #aa3300;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #881100;
}


.wbpactive {
  /* Set the marker size here */
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.5rem;
  /* Set the marker color here */
  background: #4ba814;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #4ba814;
}

.wbpundrill {
  /* Set the marker size here */
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.5rem;
  /* Set the marker color here */
  background: #e0d864;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #e0d864;
}

.wbpplugged {
  /* Set the marker size here */
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.5rem;
  /* Set the marker color here */
  background: #cf9414;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #cf9414;
}

/* inner circle (optional if you don't need the central dot) */
.wbpinactive::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.wbpinactive::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}



/* inner circle (optional if you don't need the central dot) */
.wbpundrill::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.wbpundrill::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}

/* inner circle (optional if you don't need the central dot) */
.wbpplugged::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.wbpplugged::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}


/* inner circle (optional if you don't need the central dot) */
.wbpactive::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.wbpactive::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}

/* inner circle (optional if you don't need the central dot) */
.marker::before {
    content: "";
    background: white;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    /* optional fanciness */
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.marker::after {
    content: "";
    background: rgba(128, 128, 128, 0.2);
    width: 75%;
    height: 75%;
    border-radius: 100%;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}

.asset-title{
  font-size:small;
  color: white;
  outline-color: black;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
