
.dockManagerContent {
    padding: 0.5rem;
    height: 100%; 
    width: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    /* background: orange;  */
}

.dockManagerFull {
    padding: 0rem;
    margin: 0rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.dockManagerFrame {
    padding: 0rem;
    margin: 0rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
