/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
  }

  
  code {
    color: #E01A76;
  }

  .column2{
    column-count: 2 ;
    /* border-style: ridge  */
  }
  
  .btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
  }
  
  .btn-no-border {
      border: 0 !important;
      box-shadow: none;
      background-color: transparent;    
  }
  
  .btn-no-border:focus {
    border: 0 !important;
    box-shadow: none;
    background-color: transparent;    
}
  .alway-on-top {
      z-index: 20000;
  }
  .alway-on-top-more {
    z-index: 25000;
}
.alway-on-top-more-more {
  z-index: 30000;
}
.alway-on-top-fiix {
  z-index: 10000;
}
  .alway-on-bottom {
    z-index: -1;
}
.alway-on-bottom-more{
  z-index: -100;
}

.alway-in-between {
  z-index: 200;
}
.alway-in-between-more{
  z-index: 300;
}
.alway-in-between-more-more{
  z-index: 400;
}


.alway-in-between2 {
  z-index: 270;
}

.alway-in-between3 {
  z-index: 290;
}
  .max-div-width {
    max-width: 80vw;
  }

  .max-txt-width {
    max-width: 300px;
  }

  .float-left{
    float: left;
  }

  .float-right{
    float: right;
  }

  .marginRow{
    margin:0px !important; 
  }

  .row-bottom-margin { margin-bottom:2px; }

  .custom-modal-style {
    width: 80vw;
    left: -25vw
    /* height: 802px; */
  }

  .eventDetail-modal-style {
    width: 40vw;
    left: -5vw
    /* height: 802px; */
  }

  .costDetail-modal-style {
    width: 40vw;
    left: -5vw
    /* height: 802px; */
  }

  
  .oilTicketDetail-modal-style {
    width: 40vw;
    left: -5vw;
    /* height: calc(100vh - 10px) */
    /* height: 50px;  */
    /* height: 802px; */
  }

  .userGroupDetail-modal-style {
    width: 25vw;
    left: -5vw;
    
    /* height: 802px; */
  }

  .safetyObsDetail-modal-style {
    width: 60vw;
    left: -18vw
    /* height: 802px; */
  }
  .safetyObsDetail-modal-mobile-style {
    width: 100%;
    /* left: -18vw */
    /* height: 802px; */
  }

  .modal-header.primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
  }
  
  .modal-header.approve {
    color: #fff;
    background-color: #61a066;
    border-color: #61a066;
  }

  .modal-header.submit {
    color: #fff;
    background-color: #b6a065;
    border-color: #b6a065;
  }

  .modal-header.reject {
    color: #fff;
    background-color: #ad5f55;
    border-color: #ad5f55;
  }

  .modal-header.incomplete {
    color: #fff;
    background-color: transparent;
    border-color: #ad5f55;
  }
 
  .modal-header.closeoutRequested {
    /* color: #FFDDAF; */
    background-color: #FFDDAF;
    border-color: #FFDDAF;
  }


  .form-error {
    border-radius: 5px;
    border: 1px solid #e74c3c;
}
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
  .floatButtonCancel{
    float:right;
    margin-top: 15px;
    
  }

  .floatButtonAdd{
    float:right;
    margin-top: 15px;
    margin-right: 10px;
  }

.greenColor{
    pointer-events:none;
    background-color: #61a066;
}

.greenColorPass{
  background-color: #61a066;
}
.redColor{
    pointer-events:none;
    background-color: #ad5f55;
}
.redColorFail{
  background-color: #ad5f55;
}
.defaultColor{
  background-Color: white
}
.defaultColorDisable{
  pointer-events:none;
  /* background-Color:rgba(128, 128, 128, 0.144) */
  background-Color:rgba(19, 198, 211, 0.089)
  
}

.selectBorderLess {
  border:0px;
  outline:0px;
  background-color: transparent;   
}
select:disabled {
  background: transparent;
}


/* .alert {
  display:inline-block;
} */

 .alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}
.alert-shown2 {
  opacity: 1;
  transition: all 250ms linear;
  margin-left: 30px
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 1s;
} 

.full-screen-modal {
  width: 100vw;
  height: 100vh;
}


.full-screen-modal2 {
  width: 100vw;
  height: 150vh;
}

.report-style-class {
  height: 80vh;
  width: 100%;
}
.App {
  text-align: center;
}

.custom-modal {
  max-width: 1400px; /* Set your desired width */
}
/* .tohover{
  border: none;
  width: 32px;
  height: 32px;
  background-color: #eee;
  transition: all ease-in-out 0.2s;
  cursor: pointer; 
} */
/* .tohover:hover {
	content: attr(title);
	position: absolute;
 
	bottom: -1px;
	padding: 1px;
	background: #000;
	color: #fff;
	font-size: 10px;
	white-space: pre;
  z-index: 20000;
} */



/* input[type="select"]:disabled{
  background-color:transparent;
} */


.text-danger {
  color: #dc3545;
  font-size: 0.875rem;
}

.form-control-feedback {
  display: block;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}


.form-container {
  display: flex;
  flex-direction: column;
  min-height: 150px;  /* Adjust based on your needs */
}

.footer-buttons {
  margin-top: auto;   /* Pushes it to the bottom */
}

.tab-content-container {
  min-height: 110px;  /* Adjust based on your needs */
}

.react-select__value-container--is-disabled {
  pointer-events: auto !important;
}


.centered-text {
  text-align: center;
  margin: 0 auto;
}

.compact-tabs .nav-item .nav-link {
  padding: .5rem .75rem; /* adjust as necessary */
}

.cost-group {
  background-color: #f7f7f7; /* Light grey background */
  padding: 15px;
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tab-content-fixed-height {
  height: 250px; /* adjust this value based on your desired height */

}

.custom-label {
  margin-bottom: 0.5rem; /* adjust as needed, this reduces the default margin */
}

.custom-input {
  margin-top: 0.5rem;  /* adjust as needed, this reduces the default margin */
}

.field-group {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* takes full width */
}

.field-label {
  text-align: right;
  margin-bottom: 4px; /* adjust as needed */
}

/* .scrollable-grid {
  width: 100%;
  overflow-x: auto;
} */

.bold-summary {
  font-weight: bold;
  font-size: 1.2em;  /* optional: make the font a bit larger */
}



.custom-modal-workorder {
  max-width: 1000px; /* Set your desired width */
  min-height: 10px;
}

.modalBody {
  padding-bottom: 60px;
}


.scrollable-container {
  width: 100%; 
  max-width: 100%; 
  overflow-x: auto;
  white-space: nowrap;
}

.scrollable-container {
  width: 100%; 
  max-width: 100%; 
  overflow-x: auto;
  white-space: nowrap;
  border: 1px solid #ced4da; /* Style to match the input fields */
  padding: 0.375rem 0.75rem; /* Style to match the input fields */
  border-radius: 0.25rem; /* Style to match the input fields */
  background-color: #e6e6e6; /* Style to match the input fields */
}


.ml-6 {
  margin-left: 3.5rem; /* or whatever value you determine is the next step in your spacing scale */
}

/* To specify height and font size */
.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
  height: 30px;
  font-size: 14px;
}

/*Reactsrap Input CSS*/
.my-custom-reactstrap-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: right;
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Aligns items to the right */
  align-items: center; /* Centers items vertically */
  gap:8px; /* Adds space between items */
}


.e-input:not(:valid), .e-input:valid, .e-float-input.e-control-wrapper input:not(:valid), .e-float-input.e-control-wrapper input:valid, .e-float-input input:not(:valid), .e-float-input input:valid, .e-input-group input:not(:valid), .e-input-group input:valid, .e-input-group.e-control-wrapper input:not(:valid), .e-input-group.e-control-wrapper input:valid, .e-float-input.e-control-wrapper textarea:not(:valid), .e-float-input.e-control-wrapper textarea:valid, .e-float-input textarea:not(:valid), .e-float-input textarea:valid, .e-input-group.e-control-wrapper textarea:not(:valid), .e-input-group.e-control-wrapper textarea:valid, .e-input-group textarea:not(:valid), .e-input-group textarea:valid {
  font-size: 14px;
  height: 25px;
  
}

.e-ddl.e-input-group.e-control-wrapper .e-input {
  font-size: 14px;
}

input:disabled {
  color: black;
}

.header-tabs-container {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: white; /* Ensure background color matches */
  border-bottom: 1px solid #dee2e6; /* Optional border for separation */
  height: 60px; /* Adjust height as needed */
}

.tabs-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.header-container {
  display: flex;
  justify-content: center;
  flex: 2;
  text-align: center;
}

.header-icon {
  height: 30px;
  width: 30px; /* Adjust as needed */
  margin-right: 10px; /* Adjust as needed */
}

.spacer {
  flex: 1;
}