
.no-border {
    border: 0 !important;
    box-shadow: none;
    background-color: transparent;
}


.last-dropdown {
    transform: translateX(-90%);
    z-index: 20000;
}

.btn-circle {
    width: 40px;
    height: 40px;
    padding: 6px 0px;
    border-radius: 20px;
    text-align: center;
    font-size: 15px;
    line-height: 1.42857;
}