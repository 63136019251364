

.print-preview-button {
    @media print {
      display: none;
    }
  }
  
  .print-component {
    display: none;
    @media print {
      display: table;
      .table-footer > tr > td{
        text-align: center;
        background-color: grey;
        color: white;
      }
    }
  }

  .temp-class-for-height {
      @media not print {
      width: 720px;
      visibility: hidden;
      display: table;
      }
    }

    @page {
      size: auto;
      margin: 10mm;
    }