.pager-root {
    height: 50px;
    padding: 5px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.26);
    background: #f4f4f4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

    .pager-root > * {
        margin: 0px 5px;
    }

.pager-text {
    width: 80px;
    text-align: center;
}

.icon-button {
    display: flex;
    user-select: none;
    cursor: pointer;
}

.icon-button-disable {
    cursor: default;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
}
